const routeKeys = {
  Root: "/",
  InternalServerError: "/500",
  NotFound: "/404",
  LobSelection: "line-lob-selection",
  uploaddocument: "/uploaddocument",
  advanceFilterModel: "/advanceFilterModel",
  resendInsuredSignatureSchema: "/resendInsuredSignatureSchema",
  claim: "/claim",
  success: "/success",
  thankyou: "payment/thankyou",
  manualAddressModel: "/manualAddressModel",
  manualAddressSchemaUI: "/manualAddressSchema",
  singleUser: "/singleUser",
  singleProperty: "/singleProperty",
  DashboardAccountHub: "/Dashboard/Accounthub",
  QuoteLanding: "/Quote/Landing",
  QuoteQuickQuote: "/Quote/QuickQuote",
  QuickQuoteRating: "/Quote/QuickQuoteRating",
  ApplicationPolicyHolder: "/Application/PolicyHolder",
  ApplicationProperty: "/Personal/HomeOwners/Application/Property",
  ApplicationRisk: "/Generic/Application/Risk",
  ApplicationRiskQualifiers: "/Application/RiskQualifiers",
  ApplicationCoverage: "/Application/Coverage",
  ApplicationSummary: "/Application/Summary",
  EndorsementPolicyHolder: "/Endorsement/PolicyHolder",
  EndorsementProperty: "/Personal/HomeOwners/Endorsement/Property",
  EndorsementRisks: "/Generic/Endorsement/Risk",
  EndorsementRiskQualifiers: "/Endorsement/RiskQualifiers",
  EndorsementCoverage: "/Endorsement/Coverage",
  EndorsementSummary: "/Endorsement/Summary",
  CancelLandingRate: "/Personal/HomeOwners/Cancel/Landing-Rate",
  CancelLandingBind: "/Personal/Homeowners/Cancel/Cancel-Bind",
  CancelLandingHelloSign: "/Commercial/Auto/Cancel/Landing-HelloSign",
  CancelLanding: "/Cancel/Landing",
  ReinstatementLanding: "/Reinstate/Landing",
  RenewalBusinessInfo: "/Commercial/Auto/Renewal/BusinessInfo",
  RenewalTruckTrailerInfo: "/Commercial/Auto/Renewal/TruckTrailer",
  RenewalVehicleInfo: "/Commercial/Auto/Renewal/VehicleInfo",
  RenewalDriverInfo: "/Commercial/Auto/Renewal/DriverInfo",
  RenewalSummary: "/Commercial/Auto/Renewal/Summary",
  UnitCoverageInfo: "/Commercial/Auto/Quote/UnitCoverageInfo",
  PolicyCoverageInfo: "/Commercial/Auto/Quote/PolicyCoverageInfo",
  ReinstateSubmit: "/Personal/HomeOwners/Reinstate/Landing-Submit",
  CancellationSubmit: "/Personal/HomeOwners/Cancel/Landing-Submit",
  applicationCoverage: "/application/coverage",
  endorsementCoverage: "/endorsement/coverage"
}

const buttonRouteKeys = {
  LatLongNotification: "/Personal/HomeOwners/Quote/LatLongNotification",
  AdjustPricing: "/Personal/HomeOwners/Application/Summary/AdjustPricing",
  AddRemoveOptional:
    "/Personal/HomeOwners/Application/Summary/AddRemoveOptional",
  OfferQuote: "/Personal/HomeOwners/Application/Summary/OfferQuote",
  ReferralDecline: "/Application/OnReferralDecline",
  PolicyInForce: "/Personal/HomeOwners/Application/Summary/Bind",
  StartHelloSign:
    "/Personal/HomeOwners/Application/Summary/BindSuccess-HelloSign",
  BindRequest: "/Personal/HomeOwners/Application/Summary/BindRequest",
  StartEndorsement: "/Endorsement/StartEndorsement",
  RevertPolicy: "/Personal/HomeOwners/Application/RevertPolicy",
  HoldEndorsement: "/Personal/HomeOwners/Endorsement/HoldEndorsement",
  OfferEndorsement: "/Personal/HomeOwners/Endorsement/OfferEndorsement",
  ApproveEndorsement: "/Endorsement/ApproveEndorsement",
  ReferredEndorsement: "/Endorsement/ReferredEndorsement",
  RejectEndorsement: "/Endorsement/RejectEndorsement",
  RateEndorsement: "Endorsement/Coverage",
  RevertEndorsement: "/Endorsement/RevertEndorsement",
  RevertTransaction: "/Cancel/RevertCancellation",
  EffectiveDateChange:
    "/Personal/HomeOwners/Application/Summary/UpdatePolicyVersion",
  EndosementDownloadDraft: "/Endosement/DownloadDraft"
}
// coverage array keys
const CoverageKeys = {
  PTIV: "PTIV",
  CoverageA: "coverageA",
  CoverageB: "coverageB",
  CoverageC: "coverageC",
  CoverageD: "coverageD",
  CoverageE: "coverageE",
  CoverageF: "coverageF",
  LossAssessment: "lossAssessment",
  OrdinanceOrLaw: "ordinanceOrLaw",
  IncreasedCovC: "increasedCovC",
  PersonalInjuryCover: "personalInjuryCover",
  SpecialComputerCoverage: "computerEquipmentCoverage",
  ReplacmentCostContents: "replacmentCostContents",
  SpecialPersonalPropertyCoverage: "specialPersonalPropertyCoverage",
  WaterBackup: "waterBackup",
  ExtendedReplacementCost: "increasedReplacementCost",
  EquipmentBreakdownAndServiceLine: "EquipmentBreakdownAndServiceLine",
  waterLeakDetectionDevice: "waterLeakDetectionDevice",
  ACVRoofLossSettlement: "ACVRoofLossSettlement",
  ScreenEnclosureOrCarport: "ScreenEnclosureOrCarport",
  ScreenEnclosureOrCarportLimit: "ScreenEnclosureOrCarportLimit",
  DogLiability: "DogLiability",
  SinkHoleCoverage: "sinkholeCoverage",
  WindstormOrHailExclusion: "WindstormOrHailExclusion",
  LimitedFungiCoverage: "limitedFungiCoverage",
  GolfCartCoverage: "golfCartCoverage",
  GolfCartCoverageDropdown: "GolfCartCoverageDropdown",
  IdentityFraudExp: "identityTheft",
  BuildersRiskExtentedCoverages: "buildersRiskExtentedCoverages",
  TheftOfBuildingMaterialsCoverage: "theftOfBuildingMaterialsCoverage",
  MechanicalBreakDownCoverage: "mechanicalBreakDownCoverage",
  AllOtherPerils: "allOtherPerils",
  HurricaneDeductible: "hurricaneDeductible",
  SinkHoleDeductible: "sinkholeDeductible",
  RoofDeductible: "roofDeducitble",
  SmokeDeductible: "smokeDeductible",
  TheftDeductible: "theftDeductible",
  WaterDeductible: "waterDeductible",
  WindDeductible: "windDeductible",
  TIV: "TIV",
  DogLiabilitySection: "dogLiability",
  AddDogLiabilitySection: "add-dogLiabilitySection",
  addScheduledPersonalProperty: "add-ScheduledPersonalPropertySection",
  ScheduledPersonalPropertyDropdowna: "ScheduledPersonalPropertyDropdowna",
  SalesPrice: "SalesPrice",
  WaterDamageCoverage: "WaterDamageCoverage"
}

const policySchemaKeys = {
  AgencyDetails: "agencydetails",
  AgentDetails: "agentdetails",
  Builder: "builder",
  Community: "community",
  ModelPlan: "modelPlan",
  PropertyAddress: "propertyAddress",
  setPropertyAddress: "setpropertyAddress",
  UnderWriterDetails: "underwriterdetails",
  AddressLine1: "addressLine1",
  AddressLine2: "addressLine2",
  ManualCity: "manualCity",
  ManualState: "manualState",
  Zip: "zip",
  IsManualAddress: "addmanualaddress",
  OccupancyType: "occupancyType",
  FireScore: "fireScore",
  BrushFire: "brushFire",
  DateOfBirth: "DOB",
  CoverageA: "coverageA",
  CoverageB: "coverageB",
  CoverageC: "coverageC",
  CoverageD: "coverageD",
  CoverageE: "coverageE",
  CoverageF: "coverageF",
  Isapplicantlabel: "isapplicantlabel",
  AllOtherPerils: "allOtherPerils",
  TheftDeductible: "theftDeductible",
  InsuredFullName: "insuredFullName",
  InsuredDOB: "applicantDOB",
  SecondaryDOB: "secondaryApplicantDOB",
  MaritalStatus: "maritalStatus",
  RelationToPrimary: "RelationToPrimary",
  DesiredCoverageStartDate: "desiredCoverageStartDate",
  ClaimsDetails: "claimsDetails",
  HasJointPolicyHolder: "hasJointPolicyHolder",
  RoofUpdateType: "roofUpdateType",
  HeatingUpdateType: "heatingUpdateType",
  ElectricalUpdateType: "electricalUpdateType",
  PlumbingUpdateType: "plumbingUpdateType",
  RoofUpdateYear: "roofUpdateYear",
  HeatingUpdateYear: "heatingUpdateYear",
  ElectricalUpdateYear: "electricalUpdateYear",
  PlumbingUpdateYear: "plumbingUpdateYear",
  AdjustPremium: "adjustPremium",
  AdjustPremiumType: "adjustPremiumType",
  InspectionFee: "inspectionFee",
  PolicyFee: "policyFee",
  AdjustPremiumReason: "adjustPremiumReason",
  IsPropertyLLC: "llcType",
  YearBuilt: "yearBuilt",
  PolicyTerm: "policyTerm",
  IsMailingSame: "isMailingSame",
  ClaimsDateofLoss: "claimsDateofLoss",
  EndorsementEffectiveDate: "endorsementEffectiveDate",
  RiskType: "riskType",
  RiskValue: "riskValue",
  EditEffectiveDate: "policyEffectiveDate",
  PolicyEffectiveDate: "policyEffectiveDate",
  PolicyExpiryDate: "policyExpiryDate",
  RoofType: "roofType",
  RoofCover: "roofCover",
  RoofShape: "roofShape",
  RoofDeckAttachment: "RoofDeckAttachment",
  FirstName: "firstName",
  MiddleName: "middleName",
  LastName: "lastName",
  PayMethod: "paymethod",
  Payplan: "payplan",
  MortgageeBill: "Mortgagee Bill",
  PropertyAddressCity: "propertyAddress-City",
  PropertyAddressAddressLine1: "propertyAddress-AddressLine1",
  PropertyAddressAddressLine2: "propertyAddress-AddressLine2",
  PropertyAddressState: "propertyAddress-State",
  PropertyAddressZip: "propertyAddress-Zip",
  PropertyAddressGoogleDropdown: "locationwithedit-propertyAddress"
}

export { routeKeys, CoverageKeys, policySchemaKeys, buttonRouteKeys }
