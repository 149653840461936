import { summaryActionButtonsKeys } from "./keys"

const policyStatusMaster = {
  QuoteIndication: "Quote Indication",
  Submission: "Submission", // when we move from Quote to Application
  SubmissionDeclined: "Submission Declined",
  SubmissionDeclinedByUW: "Submission Declined by UW",
  ReferralAwaitingUWReview: "Referral - Awaiting UW Review", // When UW rules are hit
  QuoteExpired: "Quote Expired", // Calcualated in batch Job based on no.OfDays Quote is old
  QuoteOffered: "Quote Offered", // When UW approve and offer Quote submitted to him by agent
  UWQuoteDeclined: "UW - Quote Declined", // When underwriter declines Quote
  BindSignaturePending: "Bound - Awaiting Documentation / Signature", //When Bind Signature is pending by Insured and Agent
  //BindSignaturePending: 'Bind Signature Pending', //When Bind Signature is pending by Insured and Agent
  BindRequestAwaitingUWApproval: "Bind Request - Awaiting UW Approval", // Agent submitted the application now, waiting for UW approval
  BoundRequestAwaitingUWApproval: "Bound - Final UW Review",
  QuoteDeclined: "Quote Declined", // When Agent rejects the policy
  PolicyInForce: "Policy In Force", // UW Approves the policy
  BindRequestDeclinedbyUW: "Bind Request Declined by UW", // UW declined bind request by Agent
  BindRequestonHold: "Bind Request on Hold", // UW put policy in application on Hold coz some documents or something
  PolicyInForceEndorsed: "Policy In Force-Endorsed", // UW approves endorsed policy submitted
  EndorsementInitiated: "Endorsement Initiated",
  EndorsementRequestAwaitingUWApproval:
    "Policy In Force - Endorsement Request Awaiting UW Approval",
  CancellationInitiated: "Cancellation Initiated",
  CancellationOnHold: "Cancellation on Hold",
  PolicyInForceCancellationRequestPendingApproval:
    "Cancellation Request Pending Approval", // Agents cancels a policy but Waiting for UW approval
  PolicyInForceCancellationSignaturePending: "Cancellation Signature Pending",
  PolicyCancelled: "Policy Cancelled", // Policy is cancelled by UW
  ReInstatementInitiated: "Reinstate Initiated",
  PolicyInForceReInstated: "Policy ReInstated",
  EndorsementRequestDecline: "Endorsement Declined by UW",
  AwaitingPaymentApproval: "Awaiting Payment Approval",
  PolicyCancelledReinstatementRequestPendingUWApproval: "Reinstatement Request Pending Approval"
}

const transactionTypeMaster = {
  QUOTE: "QuoteOffered", // for all the Transaction > Type before bind
  QuoteOffered: "QuoteOffered",
  APPLICATION: "Application",
  POLICY: "Policy", // when policy is bound
  ENDORSEMENT: "Endorsement", // for all endorsement transactions
  CANCELLATION: "Cancellation", // for all cancellation
  REINSTATE: "Reinstate", // for all reinstatement
  RENEWAL: "Renewal" // for all renewal
}

const transactionStatusMaster = {
  INPROGRESS: "In-Progress",
  RATED: "Rated",
  READYTOCOMMIT: "Ready-To-Commit",
  COMMITTED: "Committed"
}

const premiumTypeMaster = {
  FLAT: "Flat",
  PRO_RATE: "ProRate",
  SHORT_RATE: "ShortRate"
}

const statusMaster = {
  Active: "Active",
  InActive: "InActive"
}

export {
  premiumTypeMaster as PremiumTypeMaster,
  statusMaster as StatusMaster,
  policyStatusMaster as PolicyStatusMaster,
  transactionTypeMaster as TransactionTypeMaster,
  transactionStatusMaster as TransactionStatusMaster
}
