/* eslint-disable */
import { config } from "@fortawesome/fontawesome-svg-core"
import "@fortawesome/fontawesome-svg-core/styles.css"
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js"
import "bootstrap/dist/css/bootstrap.css"
import Head from "next/head"
import Router, { useRouter } from "next/router"
import NProgress from "nprogress"
import "nprogress/nprogress.css"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import { ErrorBoundary } from "react-error-boundary"
import { Provider } from "react-redux"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { PersistGate } from "redux-persist/integration/react"
import { storeKeys } from "ui/data/constants/keys"
// import { Menus } from "ui/data/constants/menu"
import { alertMsgs } from "ui/data/personal/homeowners/constants/messages"
import { store } from "@cogitate/ui-utils-core-test"
import { useEffect, useState } from "react"
import { checkTokenValidity } from "@cogitate/ui-utils-core-test"
import {
  logoutUser,
  resetPolicy,
  setStoreInternalState,
  sharedSetNavigation,
  sharedSetProgress,
  setStoreState
} from "@cogitate/ui-utils-core-test"
import { reactPlugin } from "@cogitate/ui-utils-core-test"
import {
  onBack,
  schemaTest,
  sendExceptionLogs
} from "@cogitate/ui-utils-core-test"
import { getInitalState } from "ui/components/initialData.js"
import { layouts } from "ui/utils/layout"
import { loadProgressMilestones } from "@cogitate/ui-utils-core-test/dist/utilities/shared/helpers"
import { loadvalidation } from "@cogitate/ui-utils-core-test/dist/utilities/shared/schemafromblob"
import { getStoreState } from "@cogitate/ui-utils-core-test/dist/utilities/pages/shared"
import { validate } from "ui/utilities/helper"
import { errorToastMsg } from "ui/utils/error"
import { commonDocsConfigs } from "ui/utilities/documenttabhelper"

config.autoAddCss = false
NProgress.configure({
  minimum: 0.85,
  easing: "ease",
  positionUsing: "",
  speed: 1000,
  trickle: true,
  trickleSpeed: 200,
  showSpinner: true,
  barSelector: '[role="bar"]',
  spinnerSelector: '[role="spinner"]',
  parent: "body",
  template: `<div class="modalLoader" role="bar">
              <div class="loadBar loadBar1"></div>
              <div class="loadBar loadBar2"></div>
              <div class="loadBar loadBar3"></div>
              <div class="loadBar loadBar4"></div>
              <div class="loadBar loadBar5"></div>
              <div class="loadBar loadBar6"></div>
              <div class="loadBar loadBar7"></div>
              <div class="loadBar loadBar8"></div>
            </div>`
})

function MyApp({ Component, pageProps }) {
  const [cssLoaded, setCssLoaded] = useState(false)
  const params = useRouter()
  const Layout = layouts[Component.layout]
  const router = useRouter()
  const [isView, setIsView] = useState(false)
  const policy = getStoreState(storeKeys.PolicyReducer)
  const Menus = getStoreState(storeKeys.DataReducer).menu

  useEffect(() => {
    const link = document.createElement("link")
    link.href = `${process.env.NEXT_PUBLIC_CDN_IMAGE_PATH}/${process.env.NEXT_PUBLIC_AZURE_IMAGE_STYLE_STORAGE_ACCOUNT_NAME}/${process.env.NEXT_PUBLIC_CLIENT}/styles/styles1.css`
    link.rel = "stylesheet"
    link.type = "text/css"
    document.head.appendChild(link)

    NProgress.start()
    link.onload = (ev) => {
      // Hide loader when CSS is loaded
      // Update state to indicate CSS is loaded
      setCssLoaded(true)
      setTimeout(() => {
        NProgress.done()
      }, 2000)
    }
    document.head.appendChild(link)
  }, [])
  Router.events.on("routeChangeStart", (url) => {
    NProgress.start()
  })
  Router.events.on("routeChangeComplete", (url) => {
    if (!url.includes("quote/summary")) NProgress.done()
  })
  const userType = ["underwriter", "customer service"].includes(
    getStoreState(storeKeys.LoggedInUserReducer)?.decodedJWT?.role.toLowerCase()
  )
    ? "UnderWriter"
    : "Agent"

  function replaceError(key, value) {
    if (value instanceof Error) {
      const newValue = Object.getOwnPropertyNames(value).reduce(
        (obj, propName) => {
          obj[propName] = value[propName]
          return obj
        },
        { name: value.name }
      )
      return newValue
    } else {
      return value
    }
  }

  const checkValidUser = () => {
    const validUser = checkTokenValidity()
    if (validUser) {
      toast.error(alertMsgs.Shared.TimeOut)
      setTimeout(() => {
        userLogout()
      }, 2000)
    }
  }

  const CurrentMilestone = (route) => {
    if (route.includes("application")) {
      return "application"
    }
    return "endorsement"
  }

  const userLogout = () => {
    logoutUser()
    // window.location.href = `https://login.microsoftonline.com/73ee5914-efd5-495f-b15d-ad62641b4d9c/oauth2/v2.0/logout?post_logout_redirect_uri=http://localhost:3000`;
    //window.location.href = `${process.env.NEXT_PUBLIC_PROJECT_URL}`;
    // window.location.href = `${process.env.NEXT_PUBLIC_BASE_PATH}/cogitate` + "?logout=1";
    window.location.href = `${process.env.NEXT_PUBLIC_BASE_PATH}/` + "?logout=1"
  }

  const onMenuSelected = async (indx, e, key, url) => {
    e.preventDefault()
    switch (key) {
      case "AccountHub":
        resetPolicy(getInitalState())
        setStoreInternalState(
          storeKeys.ApplicationReducer,
          storeKeys.MultipleRatingResponse,
          {}
        )
        sharedSetNavigation(0)
        setTimeout(() => {
          window.location.href = `${process.env.NEXT_PUBLIC_BASE_PATH}` + url
        }, 200)
        break

      case "GenerateQuote":
        if (schemaTest()) {
          toast.error("Unable to fetch schema. Kindly re-login")
          return false
        }
        sharedSetNavigation(1)
        sharedSetProgress(-1)
        resetPolicy(getInitalState())
        setStoreState(storeKeys.PolicyReducer, getInitalState())
        window.setTimeout(function () {
          window.location.href = `${process.env.NEXT_PUBLIC_BASE_PATH}` + url
        }, 100)

        break

      // case "MyCommunicationHub":
      //   window.location.href = `${process.env.NEXT_PUBLIC_BASE_PATH}` + url
      //   sharedSetNavigation(2)
      //   break

      case "Analytics":
        sharedSetNavigation(2)
        window.location.href = `${process.env.NEXT_PUBLIC_BASE_PATH}` + url
        break

      default:
        break
    }
  }

  const currentPageCheck = (indx, hurl) => {
    return window.location.href.includes(hurl)
  }

  const getCurrentPath = () => {
    return window?.location?.pathname?.split("/")
  }

  const onHandleProgressClick = (e, formName, redirectPage) => {
    e.preventDefault()

    const policy = getStoreState(storeKeys.PolicyReducer)

    const validator = loadvalidation(router.route)

    validate(validator, policy).then((error) => {
      for (const err in error) {
        errorToastMsg(null, error)
        return
      }
      //right bar navigation
      Router.push(redirectPage)
    })
  }

  if (!cssLoaded) {
    // Render NProgress loader until CSS is loaded
    return (
      <div className="modalLoader" role="bar">
        <div className="loadBar loadBar1"></div>
        <div className="loadBar loadBar2"></div>
        <div className="loadBar loadBar3"></div>
        <div className="loadBar loadBar4"></div>
        <div className="loadBar loadBar5"></div>
        <div className="loadBar loadBar6"></div>
        <div className="loadBar loadBar7"></div>
        <div className="loadBar loadBar8"></div>
      </div>
    )
  }

  return (
    <>
      <Head>
        <base href={process.env.NEXT_PUBLIC_BASE_PATH + "/"} />
        <script
          src="https://code.jquery.com/jquery-3.5.1.min.js"
          type="text/javascript"
        ></script>
      </Head>

      <Provider store={store}>
        <AppInsightsContext.Provider value={reactPlugin}>
          <ErrorBoundary
            FallbackComponent={(error, resetErrorBoundary) => {
              debugger
              const defaultErrorText =
                "Oops something went wrong. Try going back to the login page"
              const errorText =
                process.env.NEXT_PUBLIC_DISPLAY_ERROR === true
                  ? error.error != undefined
                    ? JSON.stringify(error, replaceError, 2)
                    : defaultErrorText
                  : error.error.message
              const logData = {
                app: "UI",
                errorMessage: error.error.message,
                stacktrace: error.error,
                url: window.location.href,
                client: `${process.env.NEXT_PUBLIC_CLIENT}`,
                Lob: `${process.env.NEXT_PUBLIC_DEFAULT_LINE} ${process.env.NEXT_PUBLIC_LINE_OF_BUSINESS}`,
                input: {},
                functionName: "",
                severityLevel: "Exception"
              }
              console.log({ error })
              sendExceptionLogs(logData)
            }}
          >
            <PersistGate persistor={store.__PERSISTOR} loading={null}>
              <Layout
                moveToURL={(url) => Router.push(url)}
                store={store}
                sharedSetNavigation={sharedSetNavigation}
                userLogout={userLogout}
                checkValidUser={checkValidUser}
                onMenuSelected={onMenuSelected}
                currentPageCheck={currentPageCheck}
                onHandleProgressClick={onHandleProgressClick}
                Menus={
                  userType === "Agent" && Menus
                    ? Menus.filter((e) => e.Key != "Analytics")
                    : Menus
                }
                ProgressMilestones={loadProgressMilestones(params)}
                needContinueBtn={Component.needContinueBtn}
                needBack={Component.needBack}
                onBack={(e) => {
                  onBack(getCurrentPath(), Router)
                }}
                assets={{
                  logo: `${process.env.NEXT_PUBLIC_CDN_IMAGE_PATH}/${process.env.NEXT_PUBLIC_AZURE_IMAGE_STYLE_STORAGE_ACCOUNT_NAME}/${process.env.NEXT_PUBLIC_CLIENT}/${process.env.NEXT_PUBLIC_ASSESTS_IMAGES}/logo.png`,
                  logout: `${process.env.NEXT_PUBLIC_CDN_IMAGE_PATH}/${process.env.NEXT_PUBLIC_AZURE_IMAGE_STYLE_STORAGE_ACCOUNT_NAME}/${process.env.NEXT_PUBLIC_CLIENT}/${process.env.NEXT_PUBLIC_ASSESTS_IMAGES}/logout-ico.png`
                }}
                needNext={Component.needNext}
                needSaveForLater={Component.needSaveForLater}
                onSuccessSaveForLater={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  const saveForLaterFormBtn =
                    document.getElementById("saveForLaterBtn")
                  saveForLaterFormBtn?.click()
                }}
                needCancel={Component.needCancel}
                currentStage={CurrentMilestone(params.route)}
                saveBtnText={"Save And Exit"}
                backBtnText={"Exit"}
                nextBtnText={Component.nextBtnText}
                useFloatingMenu={
                  Component.layout == "L3" && policy?.QuoteNumber != ""
                }
                FABProps={{
                  useDraggable: true,
                  floatKey: Component.layout,
                  onMenuItemClicked: (key) => {
                    if (key == "View") setIsView(true)
                    else setIsView(false)
                    return true
                  },
                  env: commonDocsConfigs.env,
                  fileProps: commonDocsConfigs.fileProps,
                  toast: commonDocsConfigs.toast,
                  uploadDocument: {
                    folderNameOptions: commonDocsConfigs.folderNameOptions,
                    getFormData: commonDocsConfigs.getFormData,
                    dmsContainerName: commonDocsConfigs.dmsContainerName
                  },
                  viewDocument: {
                    ...commonDocsConfigs
                  },
                  dialogClassName: isView ? "modal-90w" : ""
                }}
              >
                <ToastContainer
                  position="top-center"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={true}
                  closeOnClick={true}
                  rtl={false}
                  pauseOnFocusLoss={false}
                  draggable={true}
                  pauseOnHover={true}
                  theme="colored"
                />
                <Component {...pageProps} />
              </Layout>
            </PersistGate>
          </ErrorBoundary>
        </AppInsightsContext.Provider>
      </Provider>
    </>
  )
}

export default MyApp
